import api from "../../api/upload";

export default {
	namespaced: true,
	actions: {
		_uploadImage({ }, data) {
			return new Promise((resolve, reject) => {
				api.__uploadImage(data)
					.then(res => resolve(res))
					.catch(err => reject(err))
			});
		},
		_uploadMultiImage({ }, data) {
			return new Promise((resolve, reject) => {
				api.__uploadMultiImage(data)
					.then(res => resolve(res))
					.catch(err => reject(err))
			});
		},
	}
}