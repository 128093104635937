import request from "../utils/request";
import querystring from "querystring";

export default {
	__getList(query) {
		return request({
			url: `event/list?${querystring.stringify(query)}`,
			method: "GET"
		});
	},
	__createData({ query, data }) {
		return request({
			url: `event/new?${querystring.stringify(query)}`,
			method: "POST",
			data
		});
	},
	__updateData({ query, data }) {
		const { id } = data;
		return request({
			url: `event/${id}?${querystring.stringify(query)}`,
			method: "PUT",
			data
		});
	},
	__removeData({ query, id }) {
		return request({
			url: `event/${id}?${querystring.stringify(query)}`,
			method: "DELETE"
		});
	},
	__updateCounter(counter) {
		return request({
			url: "event/counter",
			method: "PUT",
			data: counter
		});
	},
}