import request from "../utils/request";
import querystring from "querystring";

export default {
	// 訂閱電子報
	__subscribe(data) {
		return request({
			url: "/auth/subscribe",
			method: "POST",
			data
		});
	},
	// 取得訂閱列表
	__getSubscriberList(query) {
		return request({
			url: `/auth/list/subscriber?${querystring.stringify(query)}`,
			method: "GET",
		});
	},
}