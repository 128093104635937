import router from "../router";
import store from "../store";

function checkPermission({ roles } = new Object) {
	return ({ meta } = new Object) => {
		const { permission } = meta;
		return permission
			&& permission.includes
			&& permission.some(item => roles.includes(item))
			|| permission;
	}
}

router.beforeEach(async (to, from, next) => {
	// console.log("%c[router]\n%o", "font-size:16px;color:green", to);
	await store.dispatch("auth/_checkLogin");	// 先檢查是否登入
	const { _auth } = store.state.auth;
	const { matched, name } = to;

	if (matched.some(({ meta }) => meta.permission)) {
		if (!_auth) {
			next("/login");
		} else if (!matched.some(checkPermission(_auth))) {
			next("/403");
		} else {
			next();
		}
	} else {
		next();
	}
});
