import _ from "lodash";
import api from "../../api/news";

export default {
	namespaced: true,
	state: {
		_news: {
			thumb: "",
			title: "",
			writer: "",
			date: "",
			details: "",
		},
		_newsList: new Array
	},
	actions: {
		_getList({ state }) {
			return new Promise((resolve, reject) => {
				api.__getList()
					.then(res => {
						state._newsList = res;
						resolve(res)
					})
					.catch(err => reject(err))
			})
		},
		_getData({ state }, id) {
			return new Promise((resolve, reject) => {
				api.__getData(id)
					.then(res => {
						state._news = res;
						resolve(res)
					})
					.catch(err => reject(err))
			})
		},
		_createData({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__createData(data)
					.then(res => {
						_.assign(state._news, _.cloneDeep(data));
						resolve(res)
					})
					.catch(err => reject(err))
			})
		},
		_updateData({ state }, { id, data }) {
			return new Promise((resolve, reject) => {
				api.__updateData({ id, data })
					.then(res => {
						_.assign(state._news, _.cloneDeep(data));
						resolve(res)
					})
					.catch(err => reject(err))
			})
		},
		_removeData({ }, id) {
			return new Promise((resolve, reject) => {
				api.__removeData(id)
					.then(res => resolve(res))
					.catch(err => reject(err))
			})
		},
		_sortList({ state }, list) {
			const origin = _.cloneDeep(state._newsList);
			state._newsList = list;
			return new Promise((resolve, reject) => {
				api.__sortList(list.map(({ id }) => id))
					.then(resolve)
					.catch(err => {
						state._newsList = origin;
						reject(err)
					})
			})
		},
	}
}