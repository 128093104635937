import _ from "lodash";
import api from "../../api/home";

export default {
	namespaced: true,
	state: {
		_home: {
			banners1: new Array,
			banners1_mobile: new Array,
			banners2: new Array,
			banners2_mobile: new Array,
			banners3: new Array,
			banners3_mobile: new Array,
			timeline: new Object
		}
	},
	actions: {
		_getData({ state }) {
			return new Promise((resolve, reject) => {
				api.__getData()
					.then(res => {
						state._home = res;
						resolve(res)
					})
					.catch(err => reject(err))
			})
		},
		_updateData({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__updateData(data)
					.then(({ message, update_data }) => {
						_.assign(state._home, _.cloneDeep(update_data));
						resolve(message);
					})
					.catch(err => reject(err))
			})
		},
	}
}