import request from "../utils/request";
import querystring from "querystring";

export default {
	__getData(id) {
		return request({
			url: `support/${id}`,
			method: "GET"
		});
	},
	__getList(query) {
		return request({
			url: `support/list?${querystring.stringify(query)}`,
			method: "GET"
		});
	},
	__createData(data) {
		return request({
			url: `support/new`,
			method: "POST",
			data
		});
	},
	__archiveData(id) {
		return request({
			url: `support/archive/${id}`,
			method: "PUT",
		});
	},
	__removeData({ query, id }) {
		return request({
			url: `support/${id}?${querystring.stringify(query)}`,
			method: "DELETE"
		});
	},
}