<template>
	<div class="quote-title row" align-x="center" align-y="center" gutter="20">
		<img src="@/assets/quote-left.png" style="width:1.8em;">
		<slot>
			<h1>{{label}}</h1>
		</slot>
		<img src="@/assets/quote-right.png" style="width:1.8em;">
	</div>
</template>

<script>
export default {
	props: ["label"]
}
</script>