import _ from "lodash";
import api from "../../api/activity";

export default {
	namespaced: true,
	state: {
		_activity: {
			title: "",
			sub_title: "",
			thumb: "",
			banners: [],
			banners_mobile: [],
			youtube: "",
			perform_dates: [],
			location: "",
			price: "",
			details: "",
		},
		_activities: new Array
	},
	actions: {
		_getList({ state }) {
			return new Promise((resolve, reject) => {
				api.__getList()
					.then(res => {
						state._activities = res;
						resolve(res)
					})
					.catch(err => reject(err))
			})
		},
		_getData({ state }, id) {
			return new Promise((resolve, reject) => {
				api.__getData(id)
					.then(res => {
						state._activity = res;
						resolve(res)
					})
					.catch(err => reject(err))
			})
		},
		_createData({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__createData(data)
					.then(res => {
						_.assign(state._activity, _.cloneDeep(data));
						resolve(res)
					})
					.catch(err => reject(err))
			})
		},
		_updateData({ state }, { id, data }) {
			return new Promise((resolve, reject) => {
				api.__updateData({ id, data })
					.then(res => {
						_.assign(state._activity, _.cloneDeep(data));
						resolve(res)
					})
					.catch(err => reject(err))
			})
		},
		_removeData({ }, id) {
			return new Promise((resolve, reject) => {
				api.__removeData(id)
					.then(res => resolve(res))
					.catch(err => reject(err))
			})
		},
		_sortList({ state }, list) {
			const origin = _.cloneDeep(state._activities);
			state._activities = list;
			return new Promise((resolve, reject) => {
				api.__sortList(list.map(({ id }) => id))
					.then(resolve)
					.catch(err => {
						state._activities = origin;
						reject(err)
					})
			})
		},
	}
}