import request from "../utils/request";

export default {
	__getList() {
		return request({
			url: `activity/list`,
			method: "GET"
		});
	},
	__getData(id) {
		return request({
			url: `activity/${id}`,
			method: "GET"
		});
	},
	__createData(data) {
		return request({
			url: `activity/new`,
			method: "POST",
			data
		});
	},
	__updateData({ id, data }) {
		return request({
			url: `activity/${id}`,
			method: "PUT",
			data
		});
	},
	__removeData(id) {
		return request({
			url: `activity/${id}`,
			method: "DELETE"
		});
	},
	__sortList(data) {
		return request({
			url: `activity/sort`,
			method: "PUT",
			data
		});
	},
}