import Vue from "vue";

// Element UI
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/zh-TW";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI, { locale });

// Lodash
import _ from "lodash";
Vue.prototype._ = _;

import QuoteTitle from "../components/quote-title.vue";
Vue.component("quote-title", QuoteTitle);

import Lottie from "../components/lottie.vue";
Vue.component("lottie", Lottie);