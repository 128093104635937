import api from "../../api/auth";
import { getAuth, setAuth, removeAuth } from "../../utils/auth";

export default {
	namespaced: true,
	state: {
		_auth: undefined,
		_list: new Array,
		_subscribers: new Array
	},
	mutations: {},
	actions: {
		_register({ }, data) {
			return new Promise((resolve, reject) => {
				api.__register(data)
					.then(res => resolve(res))
					.catch(err => reject(err))
			});
		},
		_joinVolunteer({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__joinVolunteer(data)
					.then(({ message, data }) => {
						state._auth = data;
						resolve(message);
					})
					.catch(err => reject(err))
			});
		},
		_verify({ }, token) {
			return new Promise((resolve, reject) => {
				api.__verify(token)
					.then(res => resolve(res))
					.catch(err => reject(err))
			});
		},
		_sendVerifyEmail({ }, data) {
			return new Promise((resolve, reject) => {
				api.__sendVerifyEmail(data)
					.then(res => resolve(res))
					.catch(err => reject(err))
			});
		},
		_login({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__login(data)
					.then(res => {
						state._auth = res;
						localStorage.setItem("login_token", res.token);
						setAuth(data);
						resolve();
					})
					.catch(err => reject(err))
			});
		},
		_logout({ state }) {
			return new Promise((resolve, reject) => {
				api.__logout()
					.then(res => {
						state._auth = undefined;
						localStorage.removeItem("login_token");
						localStorage.removeItem("golden_cart");	// 清除購物車
						const { remember_me } = getAuth();
						if (!remember_me) removeAuth();
						resolve(res);
					})
					.catch(err => reject(err))
			});
		},
		_getAuthByToken({ state }) {
			return new Promise((resolve, reject) => {
				api.__getAuthByToken()
					.then(res => {
						state._auth = res;
						resolve();
					})
					.catch(err => reject(err))
			});
		},
		async _checkLogin({ dispatch, state }) {
			try {
				if (localStorage.getItem("login_token")) {
					if (!state._auth) await dispatch("_getAuthByToken");
					return true;
				}
			} catch (error) {
				return false;
			}
		},
		_forgetPassword({ }, data) {
			return new Promise((resolve, reject) => {
				api.__forgetPassword(data)
					.then(res => resolve(res))
					.catch(err => reject(err))
			});
		},
		_resetPassword({ }, data) {
			return new Promise((resolve, reject) => {
				api.__resetPassword(data)
					.then(res => resolve(res))
					.catch(err => reject(err))
			});
		},
		_updateProfile({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__updateProfile(data)
					.then(res => {
						state._auth = res;
						resolve();
					})
					.catch(err => reject(err))
			});
		},
		_changePassword({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__changePassword(data)
					.then(({ token }) => {
						state._auth.token = token;
						localStorage.setItem("login_token", token);
						const { account, remember_me } = getAuth();
						setAuth({
							password: data.password,
							account,
							remember_me
						});
						resolve();
					})
					.catch(err => reject(err))
			});
		},
		_applyUpgrade({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__applyUpgrade(data)
					.then(({ data, message }) => {
						_.assign(state._auth, data);
						localStorage.setItem("login_token", data.token);
						resolve({ message });
					})
					.catch(err => reject(err))
			});
		},
		_getList({ state }, role) {
			return new Promise((resolve, reject) => {
				api.__getList(role)
					.then(res => {
						state._list = res;
						resolve();
					})
					.catch(err => reject(err))
			});
		},
		_getApplyPending({ state }) {
			return new Promise((resolve, reject) => {
				api.__getApplyPending()
					.then(res => {
						state._list = res;
						resolve();
					})
					.catch(err => reject(err))
			});
		},
		_handleMemberStatus({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__handleMemberStatus(data)
					.then(({ message, list }) => {
						state._list = list;
						resolve({ message });
					})
					.catch(err => reject(err))
			});
		},
		_cancelApplyUpgrade({ state }) {
			return new Promise((resolve, reject) => {
				api.__cancelApplyUpgrade()
					.then(({ message, data }) => {
						_.assign(state._auth, data);
						resolve(message);
					})
					.catch(err => reject(err))
			});
		}
	}
}