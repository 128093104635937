import request from "../utils/request";

export default {
	// 註冊
	__register(data) {
		return request({
			url: "/auth/register",
			method: "POST",
			data
		});
	},
	// 加入志工
	__joinVolunteer(data) {
		return request({
			url: "/auth/join-volunteer",
			method: "POST",
			data
		});
	},
	// 驗證
	__verify(token) {
		return request({
			url: "/auth/verify/" + token,
			method: "POST"
		});
	},
	// 驗證
	__sendVerifyEmail(data) {
		return request({
			url: "/auth/verify/send-email",
			method: "POST",
			data
		});
	},
	// 登入
	__login(data) {
		return request({
			url: "/auth/login",
			method: "POST",
			data
		});
	},
	// 登出
	__logout() {
		return request({
			url: "/auth/logout",
			method: "POST"
		});
	},
	// 用 token 取得個人資訊
	__getAuthByToken() {
		return request({
			url: "/auth/info",
			method: "GET"
		});
	},
	// 忘記密碼：寄信
	__forgetPassword(data) {
		return request({
			url: "/auth/forget-password/send-email",
			method: "POST",
			data
		});
	},
	// 忘記密碼：重設
	__resetPassword(data) {
		return request({
			url: "/auth/forget-password/reset/" + data.token,
			method: "POST",
			data
		});
	},
	// 更新個人資料（會員）
	__updateProfile(data) {
		return request({
			url: "/auth/profile/update",
			method: "PUT",
			data
		});
	},
	// 更新密碼
	__changePassword(data) {
		return request({
			url: "/auth/change-password",
			method: "PUT",
			data
		});
	},
	// 升級會員：申請
	__applyUpgrade(data) {
		return request({
			url: "/auth/register/apply-upgrade",
			method: "POST",
			data
		});
	},
	// 自己取消申請椅仔會
	__cancelApplyUpgrade() {
		return request({
			url: "/auth/register/cancel-apply-upgrade",
			method: "POST"
		});
	},
	// 取得會員列表
	__getList(role) {
		return request({
			url: "/auth/list/" + role,
			method: "GET"
		});
	},
	// 取得會員列表（正在申請椅仔會員）
	__getApplyPending() {
		return request({
			url: "/auth/list/apply-pending",
			method: "GET"
		});
	},
	// 取得會員列表（正在申請椅仔會員）
	__handleMemberStatus({ id, data }) {
		return request({
			url: "/auth/handle-member-status/" + id,
			method: "POST",
			data
		});
	},
}