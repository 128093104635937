import Vue from "vue"
import VueRouter from "vue-router"
import defaultLayout from "./layouts/default.vue";
import managerLayout from "./layouts/manager.vue";

Vue.use(VueRouter)

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			component: defaultLayout,
			children: [
				{
					path: "",
					component: () => import("./views/default/Home.vue")
				},
				{
					path: "about",
					component: () => import("./views/default/about/About.vue")
				},
				{
					path: "about/founder",
					component: () => import("./views/default/about/Founder.vue")
				},
				{
					path: "about/mother",
					component: () => import("./views/default/about/Mother.vue")
				},
				{
					path: "activity",
					component: () => import("./views/default/activity/Overview.vue")
				},
				{
					path: "activity/:id",
					component: () => import("./views/default/activity/Details.vue"),
					props: true
				},
				{
					path: "news",
					component: () => import("./views/default/news/Overview.vue"),
				},
				{
					path: "news/:id",
					component: () => import("./views/default/news/Details.vue"),
					props: true
				},
				{
					path: "portfolio",
					component: () => import("./views/default/portfolio/Overview.vue"),
				},
				{
					path: "portfolio/:id",
					component: () => import("./views/default/portfolio/Details.vue"),
					props: true
				},
				{
					path: "perform",
					component: () => import("./views/default/perform"),
					children: [
						{
							path: "",
							redirect: "start"
						},
						{
							path: "start",
							component: () => import("./views/default/perform/Start.vue"),
						},
						{
							path: "promoter",
							component: () => import("./views/default/perform/Promoter.vue"),
						},
						{
							path: "director",
							component: () => import("./views/default/perform/Director.vue"),
						},
						{
							path: "event",
							component: () => import("./views/default/perform/Event.vue"),
						},
						{
							path: "support",
							component: () => import("./views/default/perform/Support.vue"),
						}
					]
				},
				{
					path: "product",
					component: () => import("./views/default/product/Overview.vue"),
				},
				{
					path: "product/:id",
					component: () => import("./views/default/product/Details.vue"),
					props: true,
				},
				{
					path: "join",
					component: () => import("./views/default/join/Overview.vue"),
				},
				{
					path: "join/member",
					component: () => import("./views/default/join/Member.vue"),
				},
				{
					path: "join/donor",
					component: () => import("./views/default/join/Donor.vue"),
				},
				{
					path: "join/volunteer",
					component: () => import("./views/default/join/Volunteer.vue"),
				},
				{
					path: "cart",
					component: () => import("./views/default/Cart.vue"),
					meta: {
						permission: true,
					}
				},
				{
					path: "order",
					component: () => import("./views/default/order/Overview.vue"),
					meta: {
						permission: true,
					}
				},
				{
					path: "order/:id",
					component: () => import("./views/default/order/Details.vue"),
					props: true,
					meta: {
						permission: true,
					}
				},
				{
					path: "profile",
					component: () => import("./views/Profile.vue"),
					meta: {
						permission: true,
					}
				},
				{
					path: "login",
					component: () => import("./views/Login.vue"),
				},
				{
					path: "forget-password",
					component: () => import("./views/ForgetPassword.vue"),
				},
				{
					path: "reset-password/:token",
					component: () => import("./views/ResetPassword.vue"),
					props: true
				},
				{
					path: "change-password",
					component: () => import("./views/ChangePassword.vue"),
				},
				{
					path: "register-verification/:token",
					component: () => import("./views/Verification.vue"),
					props: true
				},
			]
		},
		{
			path: "/manager",
			component: managerLayout,
			meta: {
				permission: ["admin", "manager"]
			},
			children: [
				{
					path: "",
					redirect: "home"
				},
				{
					path: "home",
					component: () => import("./views/manager/Home.vue")
				},
				{
					path: "about",
					redirect: "about/index"
				},
				{
					path: "about/index",
					component: () => import("./views/manager/about/About.vue")
				},
				{
					path: "about/founder",
					component: () => import("./views/manager/about/Founder.vue")
				},
				{
					path: "about/mother",
					component: () => import("./views/manager/about/Mother.vue")
				},
				{
					path: "activity",
					component: () => import("./views/manager/activity/Overview.vue")
				},
				{
					path: "activity/:id",
					component: () => import("./views/manager/activity/Details.vue"),
					props: true
				},
				{
					path: "news",
					component: () => import("./views/manager/news/Overview.vue"),
				},
				{
					path: "news/:id",
					component: () => import("./views/manager/news/Details.vue"),
					props: true
				},
				{
					path: "portfolio",
					component: () => import("./views/manager/portfolio/Overview.vue"),
				},
				{
					path: "portfolio/:id",
					component: () => import("./views/manager/portfolio/Details.vue"),
					props: true
				},
				{
					path: "perform",
					component: () => import("./views/manager/perform"),
					children: [
						{
							path: "",
							redirect: "event"
						},
						{
							path: "start",
							component: () => import("./views/manager/perform/Start.vue"),
						},
						{
							path: "promoter",
							component: () => import("./views/manager/perform/Promoter.vue"),
						},
						{
							path: "director",
							component: () => import("./views/manager/perform/Director.vue"),
						},
						{
							path: "event",
							component: () => import("./views/manager/perform/Event.vue"),
						},
					]
				},
				{
					path: "product",
					component: () => import("./views/manager/product/Overview.vue"),
				},
				{
					path: "product/:id",
					component: () => import("./views/manager/product/Details.vue"),
					props: true,
				},
				{
					path: "account/member-manage/apply-pending",
					component: () => import("./views/manager/account/member/ApplyPending.vue"),
				},
				{
					path: "account/member-manage",
					component: () => import("./views/manager/account/member"),
					children: [
						{
							path: "",
							redirect: "list/member"
						},
						{
							path: "list",
							redirect: "list/member"
						},
						{
							path: "list/:role",
							component: () => import("./views/manager/account/member/Member.vue"),
							props: true
						},
					]
				},
				{
					path: "subscribe",
					redirect: "subscribe/subscriber"
				},
				{
					path: "subscribe/subscriber",
					component: () => import("./views/manager/subscribe/Subscribers.vue"),
				},
				{
					path: "subscribe/subscriber/:id",
					component: () => import("./views/manager/subscribe/Subscriber.vue"),
				},
				{
					path: "subscribe/template",
					component: () => import("./views/manager/subscribe/Templates.vue"),
				},
				{
					path: "subscribe/template/:id",
					component: () => import("./views/manager/subscribe/Template.vue"),
				},
				{
					path: "order",
					component: () => import("./views/manager/order/Overview.vue"),
					meta: {
						permission: true,
					}
				},
				{
					path: "order/:id",
					component: () => import("./views/manager/order/Details.vue"),
					props: true,
					meta: {
						permission: true,
					}
				},
				{
					path: "support",
					component: () => import("./views/manager/support/Overview.vue"),
					meta: {
						permission: true,
					}
				},
				{
					path: "support/:id",
					component: () => import("./views/manager/support/Details.vue"),
					props: true,
					meta: {
						permission: true,
					}
				},
			]
		},
		{
			path: "*",
			redirect: "/"
		},
	]
})

export default router
