import api from "../../api/event";

export default {
	namespaced: true,
	state: {
		_total: 0,
		_counter: 0,
		_filterList: new Object,
		_eventList: new Array
	},
	actions: {
		_getList({ state }, query) {
			return new Promise((resolve, reject) => {
				api.__getList(query)
					.then(({ total, filterList, list, counter }) => {
						state._total = total;
						state._counter = counter;
						state._filterList = filterList;
						state._eventList = list;
						resolve();
					})
					.catch(err => reject(err))
			})
		},
		_createData({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__createData(data)
					.then(({ message, data }) => {
						state._total = data.total;
						state._filterList = data.filterList;
						state._eventList = data.list;
						resolve({ message });
					})
					.catch(err => reject(err))
			})
		},
		_updateData({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__updateData(data)
					.then(({ message, data }) => {
						state._total = data.total;
						state._filterList = data.filterList;
						state._eventList = data.list;
						resolve({ message });
					})
					.catch(err => reject(err))
			})
		},
		_removeData({ state }, id) {
			return new Promise((resolve, reject) => {
				api.__removeData(id)
					.then(({ message, data }) => {
						state._total = data.total;
						state._filterList = data.filterList;
						state._eventList = data.list;
						resolve({ message });
					})
					.catch(err => reject(err))
			})
		},
		_updateCounter({ state }, counter) {
			return new Promise((resolve, reject) => {
				api.__updateCounter(counter)
					.then(res => {
						state._counter = counter;
						resolve(res);
					})
					.catch(err => reject(err))
			})
		},
	}
}