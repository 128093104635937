import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

// Utils
require("./utils/permission");
require("./utils/component");
require("./utils/filter");

// CSS
require("./scss/reset.scss");
require("./scss/rwd.scss");
require("./scss/align.scss");
require("./scss/element.scss");
require("./scss/main.scss");

Vue.config.productionTip = false

new Vue({
	router,
	store,
	data() {
		return {
			rwd: 1
		}
	},
	methods: {
		initRWD() {
			/**
			 * desktop: 1 [>768]
			 * pad: 2 [768~426]
			 * mobile: 3 [<=425]
			 */
			this.rwd = window.innerWidth > 768 ? 1 : window.innerWidth <= 425 ? 3 : 2;
			if (window.innerWidth <= 768) this.$ELEMENT.size = "small";
		}
	},
	created() {
		this.initRWD();
		window.addEventListener("resize", this.initRWD);
	},
	render: h => h(App)
}).$mount("#app")
