import request from "../utils/request";

export default {
	__getData(db) {
		return request({
			url: `about/${db}`,
			method: "GET"
		});
	},
	__updateData({ db, data }) {
		return request({
			url: `about/update/${db}`,
			method: "POST",
			data
		});
	},
}