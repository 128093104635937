import api from "../../api/subscribe";

export default {
	namespaced: true,
	state: {
		_total: 0,
		_list: new Array,
	},
	mutations: {},
	actions: {
		_subscribe({ }, data) {
			return new Promise((resolve, reject) => {
				api.__subscribe(data)
					.then(res => resolve(res))
					.catch(err => reject(err))
			});
		},
		_getSubscriberList({ state }, query) {
			return new Promise((resolve, reject) => {
				api.__getSubscriberList(query)
					.then(({ total, list }) => {
						state._list = list;
						state._total = total;
						resolve();
					})
					.catch(err => reject(err))
			});
		},
	}
}