const TokenKey = "golden_bough_theatre";
import { Base64 } from "js-base64";

export function getAuth() {
	const auth = localStorage.getItem(TokenKey);
	// console.log(`%c[getAuth]`, "color:yellow;", auth && JSON.parse(Base64.decode(auth)) || new Object);
	return auth && JSON.parse(Base64.decode(auth)) || new Object;
}

export function setAuth(data) {
	// console.log(`%c[setAuth]`, "color:yellow;", data);
	localStorage.setItem(TokenKey, Base64.encode(JSON.stringify(data)));
}

export function removeAuth() {
	// console.log(`%c[removeAuth]`, "color:yellow;", TokenKey);
	localStorage.clear();
}

// 設置cookie
function setCookie(name, value) {
	var Days = 30;
	var exp = new Date();
	exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
	document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
}

// 拿到cookie
function getCookie(name) {
	var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
	if (arr = document.cookie.match(reg)) return unescape(arr[2]);
	else return null;
}
// 刪除cookie
function delCookie(name) {
	var exp = new Date();
	exp.setTime(exp.getTime() - 1);
	var cval = getCookie(name);
	if (cval != null) document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}

export function getToken() {
	return localStorage.getItem(TokenKey);
}

export function setToken(token) {
	localStorage.setItem(TokenKey, token);
}

export function removeToken() {
	localStorage.removeItem(TokenKey);
}