import request from "../utils/request";

export default {
	// 上傳圖片
	__uploadImage({ folder, data }) {
		return request({
			url: "/upload/image/" + folder,
			method: "POST",
			data,
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
	},
	// 上傳圖片（複數）
	__uploadMultiImage({ folder, data }) {
		return request({
			url: "/upload/image/multi/" + folder,
			method: "POST",
			data,
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
	},
}