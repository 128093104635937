<template>
	<div class="lottie">
		<div :style="style" ref="lavContainer"></div>
	</div>
</template>

<script>
import lottie from "lottie-web";

export default {
	props: {
		options: {
			type: Object,
			required: true
		},
		scale: String,
		speed: String,
		reverse: Boolean,
		onComplete: Function,
		onLoopComplete: Function
	},
	data() {
		return {
			style: {
				width: "100%",
				height: "100%",
				overflow: "hidden",
				margin: "0 auto",
				transform: `scale(${this.scale})`
			}
		}
	},
	methods: {
		init() {
			this.anim = lottie.loadAnimation({
				container: this.$refs.lavContainer,
				renderer: "svg",
				loop: this.options.loop || false,
				autoplay: this.options.autoplay || false,
				animationData: this.options.animationData,
				rendererSettings: this.options.rendererSettings,
				path: this.options.path
			});
			if (this.speed) this.anim.setSpeed(this.speed);
			if (this.reverse) {
				this.anim.loop = true;
				this.anim.setDirection(-1);
				this.anim.addEventListener("loopComplete", () => this.stop());
			};
			if (this.onComplete) this.anim.addEventListener("complete", () => this.onComplete());
			if (this.onLoopComplete) this.anim.addEventListener("loopComplete", () => this.onLoopComplete());
		},
		play() {
			if (this.anim) {
				this.anim.play();
			}
		},
		pause() {
			if (this.anim) {
				this.anim.pause();
			}
		},
		stop() {
			if (this.anim) {
				this.anim.stop();
			}
		},
		destroy() {
			if (this.anim) {
				this.anim.destroy();
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.init();
			this.$emit("animCreated", this.anim)
		});
	}
}
</script>

<style lang="scss" scoped>
.lottie {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
