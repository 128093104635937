import _ from "lodash";
import api from "../../api/product";

export default {
	namespaced: true,
	state: {
		_product: new Object,
		_productList: new Array,
		_autocomplete_option: new Object
	},
	actions: {
		_sortList({ state }, list) {
			const origin = _.cloneDeep(state._productList);
			state._productList = list;
			return new Promise((resolve, reject) => {
				api.__sortList(list)
					.then(res => resolve())
					.catch(err => {
						state._productList = origin;
						reject(err)
					})
			})
		},
		_getList({ state }) {
			return new Promise((resolve, reject) => {
				api.__getList()
					.then(res => {
						state._productList = _.cloneDeep(res);
						resolve(res);
					})
					.catch(err => reject(err))
			})
		},
		_getOnSaleList({ state }) {
			return new Promise((resolve, reject) => {
				api.__getOnSaleList()
					.then(res => {
						state._productList = _.cloneDeep(res);
						resolve(res);
					})
					.catch(err => reject(err))
			})
		},
		_getData({ state }, id) {
			return new Promise((resolve, reject) => {
				api.__getData(id)
					.then(res => {
						state._product = _.cloneDeep(res);
						resolve(res);
					})
					.catch(err => reject(err))
			})
		},
		_createData({ }, data) {
			return new Promise((resolve, reject) => {
				api.__createData(data)
					.then(res => resolve(res))
					.catch(err => reject(err))
			})
		},
		_updateData({ state }, { id, data }) {
			return new Promise((resolve, reject) => {
				api.__updateData({ id, data })
					.then(res => {
						_.assign(state._product, _.cloneDeep(res.data));
						resolve(res);
					})
					.catch(err => reject(err))
			})
		},
		_removeData({ }, id) {
			return new Promise((resolve, reject) => {
				api.__removeData(id)
					.then(res => resolve(res))
					.catch(err => reject(err))
			})
		},
		_getAutocompleteOption({ state }) {
			return new Promise((resolve, reject) => {
				api.__getAutocompleteOption()
					.then(res => {
						state._autocomplete_option = _.cloneDeep(res);
						resolve(res);
					})
					.catch(err => reject(err))
			})
		},
		_createOption({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__createOption(data)
					.then(res => {
						state._autocomplete_option = _.cloneDeep(res.autocomplete_option);
						if (!state._product.options) state._product.options = [_.cloneDeep(res.data)];
						else state._product.options.push(_.cloneDeep(res.data));
						resolve(res);
					})
					.catch(err => reject(err))
			})
		},
		_updateOption({ state }, { index, data }) {
			return new Promise((resolve, reject) => {
				api.__updateOption(data)
					.then(res => {
						state._autocomplete_option = _.cloneDeep(res.autocomplete_option);
						_.assign(state._product.options[index], _.cloneDeep(res.data));
						resolve(res);
					})
					.catch(err => reject(err))
			})
		},
		_removeOption({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__removeOption(data)
					.then(res => {
						state._autocomplete_option = _.cloneDeep(res.autocomplete_option);
						state._product.options = _.cloneDeep(res.data);
						resolve(res);
					})
					.catch(err => reject(err))
			})
		},
	}
}