import _ from "lodash";
import api from "../../api/about";

export default {
	namespaced: true,
	state: {
		_about: {
			banners: new Array,
			banners_mobile: new Array,
			article: new String
		}
	},
	actions: {
		_getData({ state }, key) {
			return new Promise((resolve, reject) => {
				api.__getData(key)
					.then(res => {
						state._about = res;
						resolve(res)
					})
					.catch(err => reject(err))
			})
		},
		_updateData({ state }, { db, data }) {
			return new Promise((resolve, reject) => {
				api.__updateData({ db, data })
					.then(res => {
						state._about = _.cloneDeep(data);
						resolve(res)
					})
					.catch(err => reject(err))
			})
		},
	}
}