import request from "../utils/request";
import querystring from "querystring";

export default {
	// 下訂單
	__placeOrder(data) {
		return request({
			url: `product/order/place-order`,
			method: "POST",
			data
		});
	},
	// 取得個人訂單列表
	__getPersonalOrderList() {
		return request({
			url: `product/order/personal`,
			method: "GET",
		});
	},
	// 取消個人訂單
	__cancelPersonalOrder(id) {
		return request({
			url: `product/order/cancel/${id}`,
			method: "PUT",
		});
	},
	// 取得訂單列表
	__getOrderList(query) {
		return request({
			url: `product/order/list?${querystring.stringify(query)}`,
			method: "GET",
		});
	},
	// 取得訂單
	__getOrder(id) {
		return request({
			url: `product/order/${id}`,
			method: "GET",
		});
	},
	// 拒絕訂單
	__rejectOrder(data) {
		return request({
			url: `product/order/reject/${data.id}`,
			method: "PUT",
			data
		});
	},
	// 接單並出貨
	__confirmOrder(id) {
		return request({
			url: `product/order/confirm/${id}`,
			method: "PUT"
		});
	},
}