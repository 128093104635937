import request from "../utils/request";

export default {
	__getData() {
		return request({
			url: `home/`,
			method: "GET"
		});
	},
	__updateData(data) {
		return request({
			url: `home/update`,
			method: "POST",
			data
		});
	},
}