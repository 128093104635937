import axios from "axios"
import store from "../store"
import { Message, Loading } from "element-ui"
import { AES, enc } from "crypto-js";
import uuid from "uuid/v4";
import { removeAuth } from "./auth";

// create an axios instance
const service = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL, // api 的 base_url
})

let loading;

// request interceptor
service.interceptors.request.use(
	config => {
		// Do something before request is sent

		// 讓每個 request 自動攜帶 token -- ["X-Token"] 為自定義key，可以根據實際情況自行修改
		config.headers["X-Token"] = store.getters.AUTH_TOKEN || localStorage.getItem("login_token");

		// 每個 request 自動攜帶 browser id
		let browswer_id = localStorage.getItem("Browser-Id");
		if (!browswer_id) {
			browswer_id = uuid();
			localStorage.setItem("Browser-Id", browswer_id);
		}
		config.headers["Browser-Id"] = browswer_id;

		// 自動將 data 加密（type 為 file 時不加密）
		if (config.headers["Content-Type"] !== "multipart/form-data") {
			if (process.env.VUE_APP_ENCRYPT_KEY) config.data = { message: AES.encrypt(JSON.stringify(config.data), process.env.VUE_APP_ENCRYPT_KEY).toString() };
		}

		loading = Loading.service({ lock: true }) // loading 畫面
		return config
	},
	error => {
		loading.close()	// 關閉 loading 畫面
		console.error(error)	// for debug
		Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	({ data }) => {
		loading.close() // 關閉 loading 畫面
		return JSON.parse(AES.decrypt(data, process.env.VUE_APP_ENCRYPT_KEY).toString(enc.Utf8))
	},
	({ response }) => {
		loading.close() // 關閉 loading 畫面
		if (response && response.status == 403) removeAuth();	// 清除 localStorage 裡的 auth 資訊
		Message.error(response.data)
		return Promise.reject(response.data)
	}
)

export default service
