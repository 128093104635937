<template>
	<section id="layout" class="default-layout col">
		<navbar />
		<div id="scrollable" ref="scrollable">
			<div style="position:relative;">
				<transition name="fade" mode="out-in" @enter="$refs['scrollable'].scrollTop = 0">
					<router-view />
				</transition>
				<back-to-top v-if="$root.rwd < 3" class="bounce-bottom" :scroll-target="$refs['scrollable']" />
			</div>
			<foot />
		</div>
	</section>
</template>

<script>
export default {
	components: {
		Navbar: () => import("@/components/navbar/default"),
		Foot: () => import("@/components/footer/default"),
		BackToTop: () => import("@/components/back-to-top"),
	}
}
</script>

<style lang="scss" scoped>
#layout {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
#scrollable {
	height: 0;
	flex: 1;
	overflow-y: auto;
}
.back-to-top {
	position: fixed;
	position: sticky;
	left: calc(100% - 80px);
	bottom: 80px;
	z-index: 999;

	&.bounce-bottom {
		animation: bounce-bottom 2s both infinite;
	}

	@keyframes bounce-bottom {
		0% {
			transform: translateY(50px);
			animation-timing-function: ease-in;
			opacity: 1;
		}
		12% {
			opacity: 1;
		}
		20% {
			transform: translateY(25px);
			animation-timing-function: ease-in;
		}
		32.5% {
			transform: translateY(12.5px);
			animation-timing-function: ease-in;
		}
		41% {
			transform: translateY(6px);
			animation-timing-function: ease-in;
		}
		46.5% {
			transform: translateY(4px);
			animation-timing-function: ease-in;
		}
		12.5%,
		27.5%,
		37.5%,
		43.5% {
			transform: translateY(0);
			animation-timing-function: ease-out;
		}
		50%,
		100% {
			transform: translateY(0);
			animation-timing-function: ease-out;
			opacity: 1;
		}
	}
}
</style>