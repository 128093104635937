<template>
	<section id="layout" class="default-layout col">
		<div class="row" style="flex:1; height:0; overflow:hidden;">
			<navbar />
			<div style="flex:1; width:0;">
				<div ref="scrollable" class="scrollable">
					<transition name="fade" mode="out-in" @enter="$refs['scrollable'].scrollTop = 0">
						<router-view />
					</transition>
				</div>
			</div>
		</div>
		<foot />
	</section>
</template>

<script>
export default {
	components: {
		Navbar: () => import("@/components/navbar/manager"),
		Foot: () => import("@/components/footer/manager"),
	},
	mounted() {
		this.$nextTick(() => {
			setTimeout(() => {
				this.$notify.warning({
					title: "溫馨提示",
					dangerouslyUseHTMLString: true,
					message: `圖片上傳完之後，<br>記得按下<b style="color:red;">「儲存」</b>以免資料消失喔！`
				});
			}, 1000);
		});
	}
}
</script>

<style lang="scss" scoped>
#layout {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.scrollable {
	width: 100%;
	height: 100%;
	overflow: auto;
	padding: 5%;
}
</style>