import _ from "lodash";
import { AES, enc } from "crypto-js";
import api from "../../api/order";

function getCart() {
	const data = localStorage.getItem("golden_cart");
	return data
		&& JSON.parse(AES.decrypt(data, process.env.VUE_APP_ENCRYPT_KEY).toString(enc.Utf8))
		|| new Array;
}

function saveCart(data) {
	if (data) {
		localStorage.setItem("golden_cart",
			AES.encrypt(
				JSON.stringify(data),
				process.env.VUE_APP_ENCRYPT_KEY
			).toString()
		);
	} else localStorage.removeItem("golden_cart");
}

export default {
	namespaced: true,
	state: {
		/**
		 * 	_cart: [
		 * 		{
		 * 			productId: "",
		 * 			optionId: "",
		 * 			name: "金枝花布T-shirt",
		 * 			amunt: 1,
		 * 			color: "洋紅色",
		 * 			size: "S",
		 * 		}
		 * 	]
		 */
		_cart: getCart(),
		_total: 0,
		_order: new Object,
		_orderList: new Array,
	},
	actions: {
		_addToCart({ state }, data) {
			const item = _.find(state._cart, _.pick(data, ["productId", "optionId"]));
			if (item) _.update(item, "amount", n => n + data.amount);
			else state._cart.push(data);
			state._cart = _.sortBy(state._cart, ["name"]);
			saveCart(state._cart);
		},
		_removeFromCart({ state }, data) {
			_.remove(state._cart, _.pick(data, ["productId", "optionId"]));
			state._cart = _.sortBy(state._cart, ["name"]);
			saveCart(state._cart);
		},
		_placeOrder({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__placeOrder(data)
					.then(res => {
						saveCart();
						state._cart = getCart();
						resolve(res);
					})
					.catch(err => reject(err))
			});
		},
		_getPersonalOrderList({ state }) {
			return new Promise((resolve, reject) => {
				api.__getPersonalOrderList()
					.then(res => {
						state._orderList = res;
						resolve();
					})
					.catch(err => reject(err))
			});
		},
		_cancelPersonalOrder({ state }, id) {
			return new Promise((resolve, reject) => {
				api.__cancelPersonalOrder(id)
					.then(({ message, data }) => {
						_.assign(_.find(state._orderList, { id }), data);
						resolve(message);
					})
					.catch(err => reject(err))
			});
		},
		_getOrderList({ state }, query) {
			return new Promise((resolve, reject) => {
				api.__getOrderList(query)
					.then(({ list, total }) => {
						state._orderList = list;
						state._total = total;
						resolve();
					})
					.catch(err => reject(err))
			});
		},
		_getOrder({ state }, id) {
			return new Promise((resolve, reject) => {
				api.__getOrder(id)
					.then(res => {
						state._order = res;
						resolve();
					})
					.catch(err => reject(err))
			});
		},
		_cancelOrder({ state }, id) {
			return new Promise((resolve, reject) => {
				api.__cancelOrder(id)
					.then(({ message, data }) => {
						_.assign(_.find(state._orderList, { id }), data);
						resolve(message);
					})
					.catch(err => reject(err))
			});
		},
		// 拒絕訂單
		_rejectOrder({ }, data) {
			return new Promise((resolve, reject) => {
				api.__rejectOrder(data)
					.then(res => resolve(res))
					.catch(err => reject(err))
			});
		},
		// 接單並出貨
		_confirmOrder({ }, id) {
			return new Promise((resolve, reject) => {
				api.__confirmOrder(id)
					.then(res => resolve(res))
					.catch(err => reject(err))
			});
		},
	}
}