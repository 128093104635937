import Vue from "vue"
import Vuex from "vuex"
import getters from "./getters"
import activity from "./modules/activity"
import news from "./modules/news"
import portfolio from "./modules/portfolio"
import event from "./modules/event"
import home from "./modules/home"
import about from "./modules/about"
import auth from "./modules/auth"
import upload from "./modules/upload"
import subscribe from "./modules/subscribe"
import product from "./modules/product"
import order from "./modules/order"
import support from "./modules/support"

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		activity,
		news,
		portfolio,
		event,
		home,
		about,
		auth,
		upload,
		subscribe,
		product,
		order,
		support,
	},
	getters,
})
