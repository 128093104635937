import api from "../../api/support";

export default {
	namespaced: true,
	state: {
		_total: 0,
		_support: new Object,
		_supportList: new Array
	},
	actions: {
		_getData({ state }, id) {
			return new Promise((resolve, reject) => {
				api.__getData(id)
					.then(res => {
						state._support = res;
						resolve();
					})
					.catch(err => reject(err))
			})
		},
		_getList({ state }, query) {
			return new Promise((resolve, reject) => {
				api.__getList(query)
					.then(({ total, list }) => {
						state._total = total;
						state._supportList = list;
						resolve();
					})
					.catch(err => reject(err))
			})
		},
		_createData({ state }, data) {
			return new Promise((resolve, reject) => {
				api.__createData(data)
					.then(res => resolve(res))
					.catch(err => reject(err))
			})
		},
		_archiveData({ state }, id) {
			return new Promise((resolve, reject) => {
				api.__archiveData(id)
					.then(res => resolve(res))
					.catch(err => reject(err))
			})
		},
		_removeData({ state }, id) {
			return new Promise((resolve, reject) => {
				api.__removeData(id)
					.then(({ message, data }) => {
						state._total = data.total;
						state._supportList = data.list;
						resolve({ message });
					})
					.catch(err => reject(err))
			})
		},
	}
}